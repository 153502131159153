import React from 'react';

const HEADER = () => {
  return (
    <>
      <div className="text">
        <div className="killcode">KILLCODE-III</div>
        <div className="killing">THE KILLINGS BEGIN ON 1ST APRIL</div>
      </div>
    </>
  );
};

export default HEADER;
